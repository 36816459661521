var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"search-results"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center",domProps:{"innerHTML":_vm._s(
        _vm.searchTerm !== null && _vm.searchTerm.length > 0
          ? ("Results for '" + _vm.searchTerm + "':")
          : 'Start by typing a movie title'
      )}}),_c('v-list',[_vm._l((_vm.searchResults),function(result,index){return [_c('v-divider',{key:index,staticClass:"my-2"}),_c('v-list-item',{key:result.imdbID},[_c('v-img',{staticClass:"mr-3",attrs:{"max-width":"70","contain":"","src":result.Poster}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(((result.Title) + " (" + (result.Year) + ")"))}}),(_vm.mobile)?_c('v-btn',{staticClass:"d-inline-block indigo darken-4 mt-5 white--text",attrs:{"rounded":"","right":"","disabled":_vm.nominationsList.length < 5
                  ? _vm.nominationsList.some(
                      function (item) { return item.imdbID === result.imdbID; }
                    )
                  : true},on:{"click":function($event){return _vm.nominate(result)}}},[_vm._v("Nominate")]):_vm._e()],1),(!_vm.mobile)?_c('v-list-item-action',[_c('v-btn',{staticClass:"d-inline-block indigo darken-4 mt-5 white--text",attrs:{"rounded":"","right":"","disabled":_vm.nominationsList.length < 5
                  ? _vm.nominationsList.some(
                      function (item) { return item.imdbID === result.imdbID; }
                    )
                  : true},on:{"click":function($event){return _vm.nominate(result)}}},[_vm._v("Nominate")])],1):_vm._e()],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }